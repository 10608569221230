*{
    font-family: "Comic Neue", cursive;
}
.Container{
    width: 1330px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.HeaderMenu{
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 85%;
	height: 100vh;
    background-color: #1B2A3D;
    padding: 24px;
    overflow-y: auto;
    transform: translateX(-100%);
	transition: all 2s ease;
    z-index: 11;
}
.HeaderMenuShadow{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, .5);
    transform: translateX(-100%);
    transition: all .4s cubic-bezier(0.45, 0.48, 0.75, 0.76) 1s;
    visibility: visible;
    z-index: 10;
}
.HeaderMenuActive{
    transform: translateX(0);
}
.HeaderMenuShadowActive{
    transform: translateX(0);
    transition: all .6s cubic-bezier(.22,.61,.24,1) 0s;
}
::-webkit-scrollbar{
    width: 8px;
  }
  
  ::-webkit-scrollbar-track{
    background: white;
    box-shadow: inset 0 0 5px white;
  }
  
  ::-webkit-scrollbar-thumb{
    background:#1B2A3D;
    border-radius: 20px;
}
.Service__wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;  
}
.Service__wrapper2{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.Service__wrapper2 div{
    width: 400px;
}

@keyframes backgroundChange {
    0% {
      background-color: #1B2A3D;
    }
    50% {
      background-color: transparent; 
      color: #1B2A3D;
    }
    100% {
      background-color: #1B2A3D; /* Конечный цвет */
    }
  }
  
  .link {
    animation: backgroundChange 5s infinite;
    transition: color 1s;
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
.Result__over{
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  bottom: -50px;
  cursor: pointer;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}
.Test__wrapper{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 20px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  animation: slideIn 0.5s ease-out;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}
